import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Box } from "@mui/material";
import Currency from "@components/currency";
import { useDispatch, useSelector } from "react-redux";
import { formatToCurrency } from "@root/util/currency";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { createOperationLog } from "@root/store/payment/api";

//updated
function ConfirmationOverview({ order, outlet, orderStatus, checkout }) {
  const dispatch = useDispatch();

  const { attributes, okkamiDetails } = outlet;
  const { currencySymbol } = outlet;
  const { orderId, squareOrderReceipt } = orderStatus;

  const { collectionMessage, confirmationMessage } = attributes;
  const { tipsAmount } = order;
  const { orderTotals = {} } = checkout;
  const totalAmount = formatToCurrency(tipsAmount + orderTotals?.totalPrice);

  const goToDeeplink = () => {
    const deeplink = okkamiDetails?.deeplink;
    dispatch(
      createOperationLog("Redirecting to deeplink from order success page...", {
        requestPayload: { okkamiDetails, attributes },
      })
    );

    window.open(deeplink, "_self");
  };

  useEffect(() => {
    dispatch(
      createOperationLog("Order completed successfully", {
        responsePayload: {
          okkamiDetails,
          attributes,
          outlet,
          orderStatus,
          checkout,
          squareOrderReceipt,
          orderId,
        },
      })
    );
  }, []);

  return (
    <Grid container rowSpacing={3}>
      {attributes.isOkkamiEnabled && (
        <Grid item xs={12} class="floating-closeBtn">
          <IconButton
            edge="center"
            color="inherit"
            onClick={goToDeeplink}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography align="center">
          <img src="/assets/images/confirmation-page.svg" alt="confirm" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="h3"
          sx={{
            padding: "0px 40px",
          }}
          gutterBottom
        >
          Thankyou for your order!
        </Typography>
        {confirmationMessage && (
          <Typography variant="h4" align="center" color="#5b9f6e" gutterBottom>
            {confirmationMessage}
          </Typography>
        )}

        {collectionMessage && (
          <Typography variant="subText6" align="center" sx={{ pt: 2 }}>
            {collectionMessage}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography align="center" variant="subText5" sx={{ color: "#7c7c7c" }}>
          Payment Completed -{" "}
          <Currency value={totalAmount} symbol={currencySymbol} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant="h3" position="relative">
          <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            width="100%"
            top="-17px"
            zIndex="0"
          >
            <img src="/assets/images/circle.svg" />
          </Box>
          <Box
            position="absolute"
            width="100%"
            left="0"
            zIndex="1"
            fontWeight="900"
            fontSize="14px"
            color="#5b9f6e"
          >
            Order # {Boolean(squareOrderReceipt) ? squareOrderReceipt : orderId}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
}

ConfirmationOverview.propTypes = {};

export default ConfirmationOverview;
