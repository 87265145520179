import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import OrderComment from "@components/order-comment";
import Agreement from "@components/agreement";
import { useDispatch } from "react-redux";
import { actions as CheckoutActions } from "@store/checkout/reducer";
import { actions as OrderActions } from "@store/order/reducer";
import { action as UiActions } from "@store/ui/reducer";

import TableOrRoomSelector from "@components/table-or-room-selector";
// import OrderComment from "@components/order-comment";
// import Agreement from "@components/agreement";
import {
  getYupSchema,
  getInitialValues,
  locationSchema,
  getCheckoutFieldsValues,
  isObject,
} from "./constants";

import FloatingButton from "@components/floating-button";
import CustomFields from "@components/custom-fields/CustomFields";
import { createOperationLog } from "@root/store/payment/api";

function ViewOrderForm({ isLoading, error }) {
  const { orderType } = useParams();
  const dispatch = useDispatch();
  const { attributes, okkamiDetails } = useSelector((state) => state.outlet);
  const { orderTypes } = useSelector((state) => state.checkout);
  const { comment, agree, name, email, ageRestriction, orderFor } = useSelector(
    (state) => state.order
  );
  const { cardDeclined } = useSelector((state) => state.payment);

  const formConfig = attributes;
  const { additionalInformation, checkoutFields } = attributes;

  const [selectedOrderType = {}] = orderTypes.filter(
    ({ orderTypeId }) => orderTypeId == orderType
  );

  const { isDelivery } = selectedOrderType;

  const continueToPayment = ({ name, email, ...params }) => {
    !!name && dispatch(OrderActions.updateName(name));
    !!email && dispatch(OrderActions.updateEmail(email));
    dispatch(
      OrderActions.updateCheckoutFields(
        getCheckoutFieldsValues(params, checkoutFields)
      )
    );
    console.log("checkout params", params);
    dispatch(
      createOperationLog("Continue to Payment post order response received", {
        responsePayload: params,
      })
    );
    dispatch(CheckoutActions.updateContinueToPay(true));
  };

  const form = useFormik({
    initialValues: getInitialValues({
      comment,
      agree,
      name,
      email,
      ageRestriction,
      orderFor,
      checkoutFields: checkoutFields || [],
      okkamiDetails,
      attributes,
    }),
    validationSchema: getYupSchema({
      isDelivery,
      ageRestriction,
      checkoutFields,
      ...formConfig,
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: continueToPayment,
  });

  const { values, errors } = form;
  const { getName, getEmail } = formConfig;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let localValue = value;
    if (isObject(value)) {
      localValue = JSON.stringify(localValue);
    }
    localStorage.setItem(name, localValue);
    form.handleChange(e);
  };

  React.useEffect(() => {
    if (Object.values(errors).length > 0) {
      const errorMessage = Object.values(errors)[0];
      dispatch(UiActions.setSnackBarError(errorMessage));
    }
  }, [errors]);

  return (
    <Grid container rowGap={2}>
      {additionalInformation && (
        <Grid item xs={12}>
          <Typography variant="subTitle5" gutterBottom>
            Additional Information
          </Typography>
          <Typography variant="subText6">{additionalInformation}</Typography>
        </Grid>
      )}
      {getName && (
        <Grid item xs={12}>
          <TextField
            variant="standard"
            label={`Name ${!formConfig.isNameRequired ? "(optional)" : ""}`}
            name="name"
            value={values.name}
            onChange={handleChange}
            // error={Boolean(errors?.name)}
            // helperText={Boolean(errors?.name) && errors?.name}
            required={formConfig.isNameRequired}
            fullWidth
          />
        </Grid>
      )}
      {getEmail && (
        <Grid item xs={12}>
          <TextField
            label={`Email address ${
              !formConfig.isEmailRequired
                ? "(for receipt, optional)"
                : "(for VAT receipt)"
            }`}
            variant="standard"
            name="email"
            value={values.email}
            onChange={handleChange}
            required={formConfig.isEmailRequired}
            // error={Boolean(errors?.email)}
            // helperText={Boolean(errors?.email) && errors?.email}
            fullWidth
          />
        </Grid>
      )}
      {checkoutFields.map((item) => (
        <Grid item xs={12}>
          <CustomFields
            label={item.label}
            variant="standard"
            name={item.name}
            type={item.type}
            value={values[item.name]}
            onChange={handleChange}
            required={item.isRequired}
            // error={Boolean(errors?.email)}
            // helperText={Boolean(errors?.email) && errors?.email}
            fullWidth
          />
        </Grid>
      ))}
      {!cardDeclined && (
        <TableOrRoomSelector
          onChange={handleChange}
          name="orderFor"
          value={values.orderFor}
        />
      )}
      <Grid item xs={12}>
        <OrderComment
          onChange={handleChange}
          name="comment"
          value={values.comment}
          inputProps={{ maxlength: attributes?.maxOrderCommentChars }}
        />
      </Grid>
      {ageRestriction && (
        <Grid item xs={12}>
          <Agreement
            onChange={handleChange}
            name="agree"
            value={values.agree}
          />
        </Grid>
      )}
      <FloatingButton
        onClick={!isLoading && !Boolean(error) ? form.handleSubmit : null}
        buttonProps={{ disabled: Boolean(error) }}
      >
        {isLoading || Boolean(error) ? "Fetching Totals..." : "Continue"}
      </FloatingButton>
    </Grid>
  );
}

ViewOrderForm.propTypes = {};

export default ViewOrderForm;
