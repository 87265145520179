import camel from "to-camel-case";

const transformCheckoutFields = (value) => {
  const values = String(value).split("|");
  const valuesObject = values.map((item) => {
    const [label, isRequired, type] = String(item).split(";");
    return {
      name: camel(label),
      label: `${label} ${isRequired !== "1" ? "(optional)" : ""}`,
      isRequired: isRequired === "1",
      type,
    };
  });
  return valuesObject;
};

export const parseJson = (val) => {
  try {
    return JSON.parse(val);
  } catch (e) {
    console.error(e);
    return [];
  }
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const createVariablesNames = (value) => {
  const [firstName, ...rest] = String(value).split(" ");
  const a = firstName.toLowerCase();
  const restNames = rest.map(capitalizeFirstLetter);
  return [a, ...restNames].join("");
};

export const transformOutletAttributes = (attributes) => {
  const newAttributes = {
    isEmailRequired: false,
    isNameRequired: false,
    getEmail: false,
    getName: false,
    hideTipping: false,
    allowSurchargeRemoval: false,
    unavailableMessage: false,
    checkoutFields: [],
    maxItemCommentChars: 10000,
    maxOrderCommentChars: 10000,
    allergenFilters: [],
    isDigitalMenu: false,
    squareEdges: false,
    tipAmounts: [10, 15, 20],
    checkoutMsgBody: "",
    checkoutMsgHeading: "",
    checkoutMessageIsEnabled: false,
    isOkkamiEnabled: false,
    okkamiApiKey: "",
  };

  for (const { key, value } of attributes) {
    switch (key) {
      case "showEmail":
        newAttributes.getEmail = value === "1";
        break;
      case "showName":
        newAttributes.getName = value === "1";
        break;
      case "mandatoryName":
        newAttributes.isNameRequired = value === "1";
        break;
      case "mandatoryEmail":
        newAttributes.isEmailRequired = value === "1";
        break;
      case "hideTipping":
        newAttributes.hideTipping = value === "1";
        break;
      case "allowSurchargeRemoval":
        newAttributes.allowSurchargeRemoval = value === "1";
        break;
      case "isActive":
        newAttributes.isActive = value === "1";
        break;
      case "isAvailable":
        newAttributes.isAvailable = Boolean(value === "1" || value === "true");
        break;
      case "unavailableMessage":
        value && (newAttributes.unavailableMessage = value);
        break;
      case "availableTime":
        value && (newAttributes.availableTime = value);
        break;
      case "collectionMessage":
        value && (newAttributes.collectionMessage = value);
        break;
      case "confirmationMessage":
        value && (newAttributes.confirmationMessage = value);
        break;
      case "additionalInformation":
        value && (newAttributes.additionalInformation = value);
        break;
      case "checkoutFields":
        newAttributes.checkoutFields = transformCheckoutFields(value);
        break;
      case "maxItemCommentChars":
        newAttributes.maxItemCommentChars = value;
        break;
      case "maxOrderCommentChars":
        newAttributes.maxOrderCommentChars = value;
        break;
      case "isDigitalMenu":
        newAttributes.isDigitalMenu = Boolean(value);
        break;
      case "allergenFilters":
        newAttributes.allergenFilters = parseJson(value).map((item) => ({
          ...item,
          label: item.name,
          key: createVariablesNames(item.name),
        }));
        break;
      case "squareEdges:Boolean":
        newAttributes.squareEdges = value === "1";
        break;
      case "tipAmounts":
        newAttributes.tipAmounts = value?.split(",").map(Number);
        break;
      case "checkoutMsgBody":
        newAttributes.checkoutMsgBody = value;
        break;
      case "checkoutMsgHeading":
        newAttributes.checkoutMsgHeading = value;
        break;
      case "okkamiApiKey":
        newAttributes.isOkkamiEnabled = Boolean(value);
        newAttributes.okkamiApiKey = value;
        break;
      default:
        break;
    }
  }

  if (newAttributes?.checkoutMsgBody && newAttributes?.checkoutMsgHeading) {
    newAttributes.checkoutMessageIsEnabled = true;
  }

  return newAttributes;
};
