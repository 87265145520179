import { createOperationLog } from "@root/store/payment/api";

export const removeAllSpaceFromString = (str) => {
  //  Remove all space from string
  const formattedString = str.replace(" ", ""); // used replace instead of trimStart to have stricter control

  return formattedString;
};

// For more information on this function refer https://pos8.atlassian.net/browse/FE-4270
export const getDecodedLocationId = ({ locationId, locationData }) => {
  createOperationLog("Called getDecodedLocationId");
  const formattedLocationId = removeAllSpaceFromString(locationId);

  // Convert input character to its equivalent digit eg. a=1, b=2, ..., z=26
  const charToDigit = (char) => {
    return char.toLowerCase().charCodeAt(0) - 96;
  };

  // Convert back to a letter to verify decoding
  const calculateChecksum = (digits) => {
    const sum = digits?.reduce((acc, digit) => acc + digit, 0);
    return String.fromCharCode(sum + 1 + 96);
  };

  // Step 1: Convert all characters except the last into digits using the formula
  const characters = formattedLocationId?.split("") || "";
  const decodedDigits = characters?.slice(0, -1).map((char) => {
    const digit = charToDigit(char);
    return Math.floor((26 - digit) / 2);
  });

  // Step 2: Verify checksum
  const providedChecksum = characters[characters.length - 1];
  const expectedChecksum = calculateChecksum(decodedDigits);

  if (providedChecksum !== expectedChecksum) {
    createOperationLog("Checksum mismatch", {
      responsePayload: {
        expectedChecksum,
        providedChecksum,
        formattedLocationId,
      },
    });

    return "";
  }

  const locationIdDecoded = locationData
    ? locationData?.value
    : decodedDigits.join("");

  return locationIdDecoded;
};

export const getQueryStringValues = (url) => {
  // Parse the URL
  const urlObj = new URL(url);

  // Get search parameters
  const params = new URLSearchParams(urlObj.search);

  // Extract the values
  const apiKey = params.get("apiKey");
  const lastName = params.get("last_name");
  const email = params.get("email");
  const locationId = params.get("room");
  const deeplink = params.get("deeplink");

  return { apiKey, lastName, email, locationId, deeplink };
};

// This function check if the locationId is decoded or direct or it is identifier and returns respective array if found.
export const checkLocation = ({ locationId, locations, decodedValue }) => {
  const formattedLocationId =
    locationId && removeAllSpaceFromString(locationId);

  return locations.filter((item) =>
    decodedValue
      ? String(item.identifier) === decodedValue ||
        String(item.locationId) === decodedValue
      : String(item.locationId) === formattedLocationId
  ); // Return [] if no match is found
};
