import * as yup from "yup";
import { schema } from "normalizr";
import { getCachedValues } from "@root/services/LocalStorageService";
import isEmailValidator from "validator/lib/isEmail";

export const getYupSchema = ({ checkoutFields, ...formConfig }) => {
  const nameSchema = yup.string().nullable();
  const emailSchema = yup.string().email().nullable();

  const nameSchemaRequired = yup.string().required().nullable().label("Name");
  const emailSchemaRequired = yup
    .string()
    .email()
    .required()
    .nullable()
    .label("Email");

  const orderForSchema = yup
    .object()
    .test(
      "orderForTest",
      "Please select table/room",
      (value) => !!value.value && !!value.type
    )
    .label("Table/Room");
  const agreeSchema = yup
    .boolean()
    .required("Please confirm age")
    .oneOf([true], "Please confirm age")
    .label("Age");

  const checkoutFieldsSchema = checkoutFields.reduce((acc, item) => {
    let schema = yup.string();
    if (item.type === "email") {
      schema = schema.email().test(
        "is-valid",
        (message) => `${message.path} is invalid`,
        (value) =>
          value
            ? isEmailValidator(value)
            : new yup.ValidationError("Invalid Email")
      );
    }
    if (item.type === "number") {
      schema = schema.min(0);
    }

    if (item.isRequired) {
      schema = schema.required();
    }

    return { ...acc, [item.name]: schema.label(item.label) };
  }, {});

  const schema = {};

  nameSchema["required"] = nameSchema["required"]();

  if (formConfig.getName) {
    schema["name"] = nameSchema;
  }
  if (formConfig.getEmail) {
    schema["email"] = emailSchema;
  }
  if (formConfig.isNameRequired) {
    schema["name"] = nameSchemaRequired;
  }

  if (formConfig.isEmailRequired) {
    schema["email"] = emailSchemaRequired;
  }

  if (formConfig.isDelivery) {
    schema["orderFor"] = orderForSchema;
  }

  if (formConfig.ageRestriction) {
    schema["agree"] = agreeSchema;
  }

  return yup.object().shape({ ...schema, ...checkoutFieldsSchema });
};

export const getInitialValues = ({
  checkoutFields,
  attributes,
  okkamiDetails,
  ...params
}) => {
  const initialValues = {
    name: "",
    email: "",
    agree: "",
    comment: "",
    orderFor: "",
    ...params,
  };
  if (attributes?.isOkkamiEnabled) {
    return {
      ...initialValues,
      name: okkamiDetails?.lastName,
      email: okkamiDetails?.email,
    };
  }

  const checkoutFieldsValues = checkoutFields.reduce(
    (acc, item) => ({ ...acc, [item.name]: getCachedValues(item.name) }),
    {}
  );

  return {
    ...initialValues,
    ...checkoutFieldsValues,
  };
};

export const locationSchema = new schema.Entity(
  "location",
  {},
  { idAttribute: "locationId" }
);

export const getCheckoutFieldsValues = (values, checkoutFields) => {
  const name = checkoutFields.map((item) => item.name);
  const value = Object.entries(values).reduce((acc, [key, value]) => {
    if (name.includes(key)) {
      return [...acc, value];
    }
    return acc;
  }, []);

  return {
    attributes: [
      {
        key: "checkoutFields",
        value: value.join("|"),
      },
    ],
  };
};

export function isObject(obj) {
  return obj === Object(obj);
}
