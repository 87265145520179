import { createSlice } from "@reduxjs/toolkit";
import { transformOutletAttributes } from "./constants";

const initialState = {
  detail: {},
  isLoading: true,
  error: null,
  image: "",
  name: "",
  outletType: "",
  apiResponse: {},
  outletId: 549,
  currencySymbol: "",
  attributes: {},
  removeSurcharges: false,
  unavailableMessage: null,
  okkamiDetails: {},
};
const outlet = createSlice({
  name: "outlet",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),
    enableRemoveSurcharges: (state, { payload }) => ({
      ...state,
      removeSurcharges: payload,
    }),

    updateOutlet: (state, { payload }) => {
      const { outlets } = payload;
      const [outlet] = outlets;
      const { attributes } = outlet;
      const unavailableMessage = outlet.unavailableMessage
        ? outlet.unavailableMessage
        : null;
      return {
        ...state,
        ...outlet,
        detail: outlet,
        outletId: outlet.outletId,
        outletType: outlet.outletType,
        orderTypes: outlet.orderTypes,
        name: outlet.name,
        image: outlet.imageUrl,
        apiResponse: payload,
        attributes: transformOutletAttributes(attributes),
        currencySymbol: outlet.currencySymbol,
        unavailableMessage,
      };
    },
    updateOkkamiDetails: (state, { payload }) => ({
      ...state,
      okkamiDetails: payload,
    }),
    updateError: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const actions = outlet.actions;

export default outlet;
