import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, TextField, Typography } from "@mui/material";
import Currency from "@components/currency";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import FloatingButton from "@components/floating-button";
import { useFormik } from "formik";
import { placeOrder } from "@store/checkout/api";
import { useDispatch } from "react-redux";

import {
  getInitialValues,
  getRoomChargePayload,
  roomChargesSchema,
} from "./constants";
import { actions as checkOutActions } from "@store/checkout/reducer";
import { action as UIActions } from "@store/ui/reducer";
import PaymentService from "@services/PaymentService";
import { multiply, add } from "mathjs";
import { formatToCurrency } from "@root/util/currency";

import PaymentFailedDialog from "@components/payment-failed-dialog";
import CheckoutService from "@services/CheckoutService";
import { orderStatus } from "@store/order-status/api";
import { createCriticalLog, createOperationLog } from "@root/store/payment/api";

function RoomChargesForm({ totalPayment, startOrder, ...props }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { outletId, orderType, provider } = useParams();

  const { tipsAmount, comment, orderFor, items, name } = useSelector(
    (state) => state.order
  );
  const { orderTotals = {}, orderSettingError } = useSelector(
    (state) => state.checkout
  );
  const outlet = useSelector((state) => state.outlet);

  const { currencyCode } = outlet;

  const updateOrderStatus = ({ orderId, statusTypeName = "FailedPayment" }) => {
    return dispatch(
      orderStatus({
        orderId,
        statusTypeName,
      })
    );
  };

  const onSave = async (formData) => {
    let ORDER_ID = null;
    const commonPayloads = {
      currency: currencyCode,
      value: totalPayment,
      RoomNumber: formData.roomNumber,
      LastName: formData.lastName,
    };
    const roomChargePaymentPayload = getRoomChargePayload({
      provider,
      commonPayloads,
    });

    try {
      props.setLoading(true);
      const { order } = await startOrder();
      const { orderId } = order;
      ORDER_ID = orderId;
      const { success } = await PaymentService.roomChargePayment({
        ...roomChargePaymentPayload,
        orderId,
      });
      if (success === true) {
        dispatch(
          createOperationLog("payment success through room charge", {
            responsePayload: { provider, orderId, roomChargePaymentPayload },
          })
        );
        updateOrderStatus({
          orderId,
          statusTypeName: "Paid",
        }).then((res) => {
          dispatch(
            createOperationLog("Tiger TMS payment successful", {
              responsePayload: { orderId },
            })
          );
          goToConfirmationPage();
        });
      } else {
        dispatch(
          createCriticalLog("payment failed through room charge", {
            responsePayload: { provider, orderId, roomChargePaymentPayload },
          })
        );
        updateOrderStatus({
          orderId,
          statusTypeName: "FailedPayment",
        });
      }
    } catch (e) {
      if (e?.status?.error) {
        updateOrderStatus({
          orderId: ORDER_ID,
          statusTypeName: "FailedPayment",
        });
        console.log("Dispatching error");
        dispatch(
          createOperationLog("Dispatching error in room charge", {
            responsePayload: {
              provider,
              orderId: ORDER_ID,
              currency: currencyCode,
              value: totalPayment,
              RoomNumber: formData.roomNumber,
              LastName: formData.lastName,
              error: e,
            },
          })
        );
        dispatch(UIActions.setSnackBarError(e?.status?.userMessage));
        props.setLoading(false);
      }
    }
  };

  const goToConfirmationPage = () => {
    dispatch(
      createCriticalLog("redirecting to confirm page", {
        responsePayload: { outletId, orderType },
      })
    );
    history.push(
      `/order-confirmation/outlet/${outletId}/order-type/${orderType}`
    );
    props.setLoading(false);
  };

  const formik = useFormik({
    initialValues: getInitialValues({ orderFor, orderType, name }),
    validationSchema: roomChargesSchema,
    onSubmit: onSave,
  });

  return (
    <>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
            Room Charge
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Last Name"
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values?.lastName}
            name="lastName"
            error={Boolean(formik.errors?.lastName)}
            helperText={
              Boolean(formik.errors?.lastName) ? formik.errors?.lastName : null
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Room Number"
            variant="standard"
            fullWidth
            onChange={formik.handleChange}
            value={formik.values?.roomNumber}
            error={Boolean(formik.errors?.roomNumber)}
            helperText={
              Boolean(formik.errors?.roomNumber)
                ? formik.errors?.roomNumber
                : null
            }
            name="roomNumber"
          />
        </Grid>
      </Grid>
      <FloatingButton onClick={formik.handleSubmit} position="fixed">
        <Box sx={{ minWith: "115px" }}>
          Pay <Currency value={tipsAmount + orderTotals.totalPrice} />
        </Box>
      </FloatingButton>
      {Boolean(orderSettingError) && (
        <PaymentFailedDialog
          onClose={() => dispatch(checkOutActions.updateOrderError(null))}
          onRetry={formik.handleSubmit}
        />
      )}
    </>
  );
}

RoomChargesForm.propTypes = {};

export default RoomChargesForm;
