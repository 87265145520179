import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actions as OrderActions } from "@store/order/reducer";
import { actions as ViewOrderPageActions } from "@store/pages/viewOrderPage";
import { normalize, schema } from "normalizr";
import { Autocomplete, Grid } from "@mui/material";
import {
  checkLocation,
  getDecodedLocationId,
} from "@root/util/decodeLocationId";

const locationSchema = new schema.Entity(
  "location",
  {},
  { idAttribute: "locationId" }
);

function TableOrRoomSelector(props) {
  const dispatch = useDispatch();

  const { orderTypes } = useSelector((state) => state.checkout);
  const { orderFor } = useSelector((state) => state.order);
  const { attributes } = useSelector((state) => state.outlet);
  const { orderType, locationId } = useParams();

  const [selectedOrderType = {}] = orderTypes.filter(
    ({ orderTypeId }) => orderTypeId == orderType
  );

  const { prompt = "Select" } = selectedOrderType;
  const locations = selectedOrderType?.locations || [];
  const decodedValue =
    locationId && isNaN(locationId) ? getDecodedLocationId({ locationId }) : "";

  const foundLocation = checkLocation({
    locations,
    locationId,
    decodedValue,
  });

  const canShowLocationSelector = !Boolean(foundLocation.length);
  const normalizedLocations = normalize(locations, [locationSchema]);

  const onChange = ({ target: { value } }) => {
    const { entities } = normalizedLocations;
    const { location } = entities;
    const { identifier } = location?.[value] || {};

    dispatch(
      OrderActions.updateOrderFor({ type: orderType, value, identifier })
    );

    props.onChange({
      target: {
        value: { type: orderType, value, identifier },
        name: props.name,
      },
    });
  };

  const { isDelivery } = selectedOrderType;

  React.useEffect(() => {
    if (orderFor?.value) {
      props.onChange({
        target: {
          value: orderFor,
          name: props.name,
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (locationId) {
      if (isDelivery === false) {
        dispatch(ViewOrderPageActions.updateShowTableAndRoomSelector(false));
      }
    }
  }, [orderTypes]);

  useEffect(() => {
    if (!canShowLocationSelector) {
      const [selectedLocation = {}] = foundLocation;
      onChange({ target: { value: selectedLocation?.locationId } });
    } else {
      if (orderFor?.value) {
        onChange({ target: { value: orderFor?.value || false } });
      }
    }
  }, [canShowLocationSelector]);

  if (!isDelivery || !canShowLocationSelector || !Boolean(orderTypes.length)) {
    // Check for hiding this
    return null;
  }

  const options = locations.map(({ locationId, name }) => ({
    value: locationId,
    label: name,
  }));

  const onUpdate = (_, { value }) => {
    onChange({ target: { value, name: "none" } });
  };

  const [selectOption = {}] = options.filter(
    ({ value }) => String(value) === String(orderFor?.value)
  );
  const { label: defaultValue } = selectOption;

  return (
    <Grid item xs={12} paddingTop={2}>
      <Autocomplete
        disablePortal
        id="outlined-select-currency"
        options={options}
        onChange={onUpdate}
        defaultValue={defaultValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={prompt}
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: attributes?.squareEdges ? "1px" : "4px",
              },
            }}
          />
        )}
        fullWidth
      />
    </Grid>
  );
}

TableOrRoomSelector.propTypes = {};

export default TableOrRoomSelector;
